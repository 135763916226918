import request from '@/utils/request'

//获取餐饮订单列表

//接口：查询订单（运营后台查询餐饮订单，小程序查询我的餐饮订单）
//地址：http://${BASE_URL}/customer-service/lrCateringOrder/queryOrderList
export function getOrderList (data) {
    return request({
        url: '/customer-service/lrCateringOrder/queryOrderList',
        method: 'post',
        data
    })
}

//根据Id获取餐饮订单详情
//接口：订单详情（小程序和运营后台使用）
//地址：http://${BASE_URL}/customer-service/lrCateringOrder/orderDetail?orderCode=#{orderCode}
export function getOrderDetail (code) {
    return request({
        url: `/customer-service/lrCateringOrder/orderDetail?orderCode=${code}`,
        method: 'get'
    })
}

//餐饮订单退款
// export function orderRefund (data) {
//     return request({
//         url: "/order-service/order/refundOrder",
//         method: 'post',
//         data
//     })
// }


//接口：小程序或运营人员操作退款申请
//地址：http://${BASE_URL}/customer-service/lrCateringOrder/submitOrderRefund
export function submitOrderRefund (data) {
    return request({
        url: "/customer-service/lrCateringOrder/submitOrderRefund",
        method: 'post',
        data
    })
}

//接口：餐饮订单审核退款
//地址：http://${BASE_URL}/customer-service/lrCateringOrder/approveOrderRefund
export function refundExamine (data) {
    return request({
        url: "/customer-service/lrCateringOrder/approveOrderRefund",
        method: 'post',
        data
    })
}



//餐饮订单退拒绝
export function orderRefundRefuse (data) {
    return request({
        url: "/order-service/orderOSM/updateOrderData2",
        method: 'post',
        data
    })
}

//获取餐饮退款订单列表
//接口：退款申请查询
//地址：http://${BASE_URL}/customer-service/lrCateringOrder/queryOrderRefund
export function getRefundOrderList (data) {
    return request({
        url: "/customer-service/lrCateringOrder/queryOrderRefund",
        method: 'post',
        data
    })
}

//接口：导出订单
//地址：http://${BASE_URL}/customer-service/lrCateringOrder/exportOrderList
export function exportOrder (data) {
    return request({
        url: "/customer-service/lrCateringOrder/exportOrderList",
        method: 'post',
        data
    })
}