<template>
  <div>
    <!-- 顶部筛选栏 -->
    <div class="flx-row ali-c">
      <el-date-picker
        v-model="dateValue"
        type="daterange"
        range-separator="至"
        start-placeholder="开始日期"
        end-placeholder="结束日期"
      ></el-date-picker>
    </div>
    <!-- 表格数据 -->
    <el-table class="mt-24 member-table" :data="tableData">
      <el-table-column label="操作时间" prop="time"></el-table-column>
      <el-table-column label="操作人员" prop="person"></el-table-column>
      <el-table-column label="操作类型" prop="type">
        <template slot-scope="scope">
          <span>{{scope.row.type==1?'编辑':'删除'}}</span>
        </template>
      </el-table-column>
      <el-table-column label="字段名" prop="fieldName"></el-table-column>
      <el-table-column label="旧字段值" prop="oldFieldVal"></el-table-column>
      <el-table-column label="新字段值" prop="newFieldVal"></el-table-column>
      <el-table-column label="备注" prop="remark"></el-table-column>
    </el-table>
    <Pagination :total="total" :pageNum="pageNum" :pageSize="pageSize" :pageSizes="pageSizes" @changePage="getData" @syncPageData="syncPageData"/>
  </div>
</template>
<script>
import request from "@/utils/request"
import Pagination from "@/components/Pagination/index"
import { getOperationLog } from "@/api/members/members_manage";
export default {
  components: {
    Pagination
  },
  data() {
    return {
      dateValue: "",
      tableData: [
        {
          date: "2019-3-21 10:23",
          name: "小命",
          oper_type: 0,
          oper: "积分",
          old_val: "100",
          new_val: "300",
          remark: "sss"
        },
        {
          date: "2019-3-21 10:23",
          name: "大命",
          oper_type: 1,
          oper: "积分",
          old_val: "100",
          new_val: "300",
          remark: "xxx"
        }
      ],
      memberId: "",
      total: 0,
      pageNum: 1,
      pageSize: 10,
      pageSizes: [10, 20, 30, 50, 100],
    };
  },
  created() {
    this.memberId = sessionStorage.getItem('MEMBER_ID')
    this.getData(this.memberId)
  },
  methods: {
    getData(id) {
      let params = {
        userId: id,
        pageNum: this.pageNum,
        pageSize: this.pageSize
      }
      getOperationLog(params).then(res => {
        if(res.data.code == 200){
          this.total = res.data.body.total
          this.tableData = res.data.body.list
        }
      })
    },
    syncPageData(data) {
      this.pageNum = data.num
      this.pageSize = data.size
    },
  },
};
</script>