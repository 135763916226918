import request from '@/utils/request'

// 获取会员活动列表
export function getMemberActivetyList(data) {
    return request({
        url: "/customer-service/lrMemberManage/queryMember",
        method: 'post',
        data
    })
}


// 根据Id获取会员详情
export function getMemberDetail(id) {
    return request({
        url: "/customer-service/lrMemberManage/userInfoDetail?userId=" + id,
        method: 'get'
    })
}

//更新积分
export function updateIntegral (data, token) {
    return request({
        url: "/customer-service/lrMemberManage/updateMemberPoints",
        method: 'post',
        headers: {
            token
        },
        data
    })
}


//选择优惠券
export function selectCoupon (data, token) {
    return request({
        url: '/coupon-service/couponCustomerRelation',
        method: 'post',
        headers: {
            token
        },
        data
    })
}

//获取地址列表
export function getAddressList (userId) {
    return request({
        url: '/shd-operation/lrUserAddress/queryAddressById?userId=' + userId,
        method: 'get'
    })
}


//接口：查询用户下的优惠券
//地址：http://${BASE_URL}/customer-service/lrCoupon/userCouponQuery
export function getCouponListOfUser (data) {
    return request({
        url: '/customer-service/lrCoupon/userCouponQuery',
        method: 'post',
        data
    })
}

//获取操作明细
export function getOperationLog (params) {
    return request({
        url: '/customer-service/lrMemberManage/queryOperationLog',
        method: 'get',
        params
    })
}

//获取积分明细
export function getPointsDetail (params) {
    return request({
        url: '/customer-service/lrMemberManage/queryPointsDetail',
        method: 'get',
        params
    })
}

// 接口：批量领取优惠券or赠送
// 地址：http://${BASE_URL}/customer-service/lrCoupon/batchReceiveCoupon
export function batchReceiveCoupon (data) {
    return request({
        url: "/customer-service/lrCoupon/batchReceiveCoupon",
        method: 'post',
        data
    })
}

//接口：领取or赠送优惠券
//地址：http://${BASE_URL}/customer-service/lrCoupon/receiveCoupon
export function singleUserReceiveCoupon (data) {
    return request({
        url: "/customer-service/lrCoupon/receiveCoupon",
        method: 'post',
        data
    })
}

//接口：根据userid获取用户活动
//地址：http://${BASE_URL}/customer-service/lrActivity/myActivity?userId=#{userId}&pageNum=#{pageNum}&pageSize#{pageSize}
export function getMemberActivityById (data) {
    return request({
        url: `/customer-service/lrActivity/myActivity?userId=${data.userId}&pageNum=${data.pageNum}&pageSize=${data.pageSize}`,
        method: 'get',
        data
    })
}
