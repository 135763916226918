<template>
  <div>
    <OrdersHomeList :isMembers='true' :memberId="memberId"/>
  </div>
</template>
<script>
import OrdersHomeList from "@/views/transactions/orders/orders_home_list";
export default {
   props: {
    memberId: {
      type: String,
      default: "",
    },
  },
  components: {
    OrdersHomeList
  }
};
</script>