<template>
  <div>
    <!-- 顶部筛选栏 -->
    <div class="flx-row ali-c">
      <el-date-picker
        v-model="dateValue"
        type="daterange"
        range-separator="至"
        start-placeholder="开始日期"
        end-placeholder="结束日期"
      ></el-date-picker>
      <el-input placeholder="输入订单号" class="search-input ml-15">
        <el-button slot="append" icon="el-icon-search"></el-button>
      </el-input>
    </div>
    <!-- 表格数据 -->
    <el-table class="mt-24 member-table" :data="tableData">
      <el-table-column label="订单编号" prop="orderNumber"></el-table-column>
      <el-table-column label="所属门店" prop="storeName"></el-table-column>
      <el-table-column label="订单类型" >
        <template slot-scope="scope">
          <span>{{scope.row.orderType==1?'正常交易':scope.row.orderType==2?'积分活动':'砍价活动'}}</span>
        </template>
      </el-table-column>
      <el-table-column label="订单金额" prop="actualPay"></el-table-column>
      <el-table-column label="付款方式" >
        <!-- <template slot-scope="scope"> -->
          <span>微信支付</span>
        <!-- </template> -->
      </el-table-column>
      <el-table-column label="订单状态" prop="status">
        <template slot-scope="scope">
          <span>{{scope.row.status==0?'未支付':
            scope.row.status==1?'已支付':
            scope.row.status==2?'已发货':
            scope.row.status==3?'已完成':
            scope.row.status==4?'待退款':
            scope.row.status==5?'已退款':
            scope.row.status==-1?'退款失败':'--'}}</span>
        </template>
      </el-table-column>
      <el-table-column label="下单时间" prop="createTime">
        <template slot-scope="scope">
          {{scope.row.createTime && scope.row.createTime.replace('T', ' ').split('.')[0]}}
        </template>
      </el-table-column>
    </el-table>
    <Pagination :total="total" :pageNum="pageNum" :pageSize="pageSize" :pageSizes="pageSizes" @changePage="getData" @syncPageData="syncPageData"/>
  </div>
</template>
<script>
import Pagination from "@/components/Pagination/index"
import { getOrderList } from "@/api/transactions/order/orders_department";
export default {
  components: {
    Pagination
  },
  data() {
    return {
      dateValue: [],
      tableData: [],
      total: 0,
      pageNum: 1,
      pageSize: 10,
      pageSizes: [10, 20, 30, 50, 100],
    };
  },
  mounted() {
    this.createCustormer = sessionStorage.getItem('MEMBER_ID')
    this.getData()
  },
  methods: {
    getData() {
      let data = {
        createCustormer: this.createCustormer,
        photoTime1: this.dateValue[0],
        photoTime2: this.dateValue[1],
        pageNum: this.pageNum,
        pageSize: this.pageSize
      }
      getOrderList(data).then(res => {
        this.total = res.data.body.total
        this.tableData = res.data.body.list
      })
    },
    syncPageData(data) {
      this.pageNum = data.num
      this.pageSize = data.size
    },
  },
};
</script>