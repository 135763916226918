<template>
  <el-table class="mt-24 member-table" border :data="infoTable">
    <el-table-column label="消费金额" prop="consumeMomey"></el-table-column>
    <el-table-column label="订单数量" prop="orderNum"></el-table-column>
    <el-table-column label="可用优惠券" prop="usefulCoupon"></el-table-column>
    <el-table-column label="可用积分" prop="surplusPoints"></el-table-column>
    <el-table-column label="关注商户" prop="concernMerchant"></el-table-column>
    <el-table-column label="报名活动" prop="activityNum"></el-table-column>
  </el-table>
</template>
<script>
export default {
  props: ['userData'],
  data() {
    return {
      infoTable: []
    };
  },
  watch: {
    userData(val) {
      this.infoTable = [val]
    },
  },
  mounted() {
    this.infoTable = [this.userData]
  },
};
</script>
<style lang="scss" scoped>
.content-wrapper {
  width: 50%;
  .content {
    width: 33.3%;
    .title {
      height: 50px;
      background: #eaeaea;
      width: 100%;
      line-height: 50px;
      border: 1px solid #ddd;
      box-sizing: border-box;
    }
    .value {
      line-height: 50px;
      height: 50px;
      border: 1px solid #ddd;
      box-sizing: border-box;
    }
  }
}
</style>