<template>
  <div>
    <!-- 顶部搜索栏 -->
    <el-row>
      <el-col :xs="24" :sm="24" :lg="24">
        <div class="ali-c" :class="{ 'pad-container': !isMembers }">
          <el-input
            placeholder="订单编号"
            class="search-input"
            clearable
            v-model="searchObj.orderNumber"
          ></el-input>
          <el-input
            placeholder="手机号"
            clearable
            v-if="!isMembers"
            class="search-input ml-15"
            v-model="searchObj.phone"
          ></el-input>

          <el-select
            clearable
            placeholder="订单状态"
            class="search-input ml-15"
            v-model="searchObj.status"
            v-if="!isMembers"
          >
            <el-option
              v-for="(item, index) in order_status"
              :key="index"
              :label="item.title"
              :value="item.id"
            ></el-option>
          </el-select>
          <el-select
            clearable
            placeholder="选择门店"
            class="search-input ml-15"
            v-model="searchObj.storeId"
            :disabled="disabled"
            v-if="!isMembers"
          >
            <el-option
              v-for="(item, index) in store_list"
              :key="index"
              :label="item.storeName"
              :value="item.id"
            ></el-option>
          </el-select>
          <label style="margin-left: 20px;">下单时间：</label>
          <el-date-picker
            v-model="searchObj.createTime"
            type="daterange"
            range-separator="至"
            value-format="yyyy-MM-dd HH:mm:ss"
            :default-time="['00:00:00','23:59:59']"
            class="search-input ml-15"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
          <label style="margin-left: 20px;">核销时间：</label>
          <el-date-picker
            v-model="writeOffTime"
            type="daterange"
            range-separator="至"
            value-format="yyyy-MM-dd HH:mm:ss"
            :default-time="['00:00:00','23:59:59']"
            class="search-input ml-15"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            @change="changeWriteOffTime()"
          >
          </el-date-picker>
          <el-button
            type="primary"
            icon="el-icon-search"
            class="ml-15"
            @click="search"
            >查询</el-button
          >
          <el-button icon="el-icon-refresh" class="ml-15" @click="reset"
            >重置</el-button
          >
<!--          <el-button icon="el-icon-upload2" type="primary" @click="exportExcel"-->
<!--            >导出</el-button-->
<!--          >-->

          <el-button type="primary" icon="el-icon-refresh" @click="exportFile()">导出</el-button>
        </div>
      </el-col>
    </el-row>
    <el-row class="mt-24">
      <el-col el-col :xs="24" :sm="24" :lg="24">
        <div :class="{ 'pad-container': !isMembers }">
          <!-- 表格 -->
          <el-table class="mt-24 member-table" :data="tableData">
            <el-table-column
              label="订单编号"
              prop="orderCode"
              width="200"
            ></el-table-column>
            <el-table-column
              label="支付流水号"
              prop="outTradeNo"
            ></el-table-column>
            <el-table-column label="客户昵称" prop="userName"></el-table-column>
            <el-table-column label="手机号码" prop="phone"></el-table-column>
            <el-table-column
              label="所属门店"
              prop="storeName"
            ></el-table-column>
            <el-table-column label="订单金额">
              <template slot-scope="scope">
                <span>￥{{ scope.row.orderActualPrice || 0 }}</span>
              </template>
            </el-table-column>
            <!-- <el-table-column label="付款方式" prop="pay_way"></el-table-column> -->
            <el-table-column label="订单状态">
              <template slot-scope="scope">
                <span>{{ getString(1, scope.row.status) }}</span>
              </template>
            </el-table-column>
            <el-table-column
              label="下单时间"
              prop="orderTime"
              width="250"
            ></el-table-column>
            <el-table-column
              label="核销时间"
              prop="offsetTime"
              width="250"
            ></el-table-column>
            <el-table-column label="操作" width="200">
              <template slot-scope="scope">
                <div class="flx-row ali-c">
                  <el-button @click="goDetail(scope.row)" type="text"
                    >详情</el-button
                  >
                  <el-button
                    type="text"
                    class="ml-10"
                    v-if="
                      (scope.row.status == -1 || scope.row.status == 1 || scope.row.status == 2 ||scope.row.status == 7) && scope.row.orderActualPrice != 0
                    "
                    @click="refund(scope.row)"
                    >退款</el-button
                  >
                </div>
              </template>
            </el-table-column>
          </el-table>
          <div class="flx-row ali-c js-c mt-24">
            <Pagination
              :total="total"
              :pageNum="pageNum"
              :pageSize="pageSize"
              :pageSizes="pageSizes"
              @changePage="search"
              @syncPageData="syncPageData"
            />
          </div>
        </div>
      </el-col>
    </el-row>

    <!-- 退款弹窗 start -->
    <el-dialog
      :visible.sync="refundVisible"
      width="450px"
      :title="'退款申请'"
      @close="cancel"
    >
      <el-form :model="form" :rules="rules" ref="form">
        <el-form-item prop="reason">
          <el-input
            v-model="form.reason"
            placeholder="请输入理由"
            type="textarea"
          ></el-input>
        </el-form-item>
      </el-form>
      <div class="flx-row ali-c js-fe mt-50">
        <el-button @click="cancel">取消</el-button>
        <el-button type="primary" @click="confirm('form')">确定</el-button>
      </div>
    </el-dialog>
    <!-- 退款弹窗 end -->
  </div>
</template>
<script>
import Pagination from "@/components/Pagination";
import { getStoreList } from "@/api/online_department_store/commodity_manage";
import {downFile2} from "@/utils/index.js";
import {
  getOrderList,
  submitOrderRefund,
  exportOrder,
} from "@/api/transactions/order/orders_repast";
import html2canvas from "html2canvas";
import vueQr from "vue-qr";
export default {
  components: {
    Pagination,
    vueQr,
  },
  props: {
    // 从会员中心 个人详情那边传值过来  isMembers
    isMembers: {
      type: Boolean,
      default: false,
    },
    // 从会员中心 个人详情那边传值过来  memberId
    memberId: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      qrText: "",
      captureObj: {},
      img: "",
      refundVisible: false, //退款弹窗
      rules: {
        reason: [
          {
            required: true,
            trigger: "blur",
            message: "理由不能为空",
          },
        ],
      },
      selectedItem: {},
      //搜索栏相关数据
      searchObj: {
        orderNumber: "",
        phone: null,
        status: null,
        storeId: null,
        createTime: "", //日期控件
      },
      form: {
        reason: "",
      },
      // 订单状态与门店列表
      order_status: [
         {
          id: -2,
          title: "已取消",
        },
        {
          id: -1,
          title: "已过期",
        },
        {
          id: 0,
          title: "待支付",
        },
        {
          id: 1,
          title: "待使用",
        },
        {
          id: 2,
          title: "已完成",
        },
        {
          id: 3,
          title: "退款待审核",
        },
        {
          id: 4,
          title: "微信退款中",
        },
         {
          id: 5,
          title: "商家拒绝退款",
        },
         {
          id: 6,
          title: "退款成功",
        },
         {
          id: 7,
          title: "退款失败",
        },
      ],
      order_type: [
        {
          id: 1,
          title: "正常交易",
        },
        {
          id: 2,
          title: "积分活动",
        },
        {
          id: 3,
          title: "砍价活动",
        },
        {
          id: 6,
          title: "多人拼团",
        },
        {
          id: 7,
          title: "限时抢购",
        },
      ],
      store_list: [],
      // 会员表格数据内容
      tableData: [],
      //用于分页
      total: 0,
      pageNum: 1,
      pageSize: 10,
      pageSizes: [10, 20, 30, 50, 100],
      url: "/customer-service/lrCateringOrder/exportOrderList",
      disabled: false,
      writeOffTime: "",
    };
  },
  mounted() {
    this.getStores();
    this.initData();
  },
  methods: {
    // 表格导出
    exportExcel() {
      let data = {
        orderCode: this.searchObj.orderNumber,
        phone: this.searchObj.phone,
        storeId: this.searchObj.storeId,
        status:
          this.searchObj.status || this.searchObj.status == 0
            ? this.searchObj.status
            : "",
        startTime: this.searchObj.createTime ? this.searchObj.createTime[0] : "",
        endTime: this.searchObj.createTime ? this.searchObj.createTime[1] : "",
        writeOffStartTime: this.searchObj.writeOffStartTime,
        writeOffEndTime: this.searchObj.writeOffEndTime,
      };
      // console.log(data);
      this.downFile(this.url, "订单", data);
      // exportOrder(data).then(res=>{
      //   // console.log(res)
      // })
    },
    //去详情
    goDetail(row) {
      // console.log("row", row);
      this.$router.push({
        name: "Orders_repast_details",
        query: {
          id: row.orderCode,
        },
      });
    },
    //初始化状态
    initData() {
      if (this.$store.getters.storeId) { //门店管理员
        this.searchObj.storeId = this.$store.getters.storeId;
        this.disabled = true;
      }

      let data = {
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        orderCode: this.searchObj.orderNumber, //订单编号
        phone: this.searchObj.phone,
        storeId: this.searchObj.storeId, //门店id
        status:
          this.searchObj.status || this.searchObj.status == 0
            ? this.searchObj.status
            : "",
        startTime: this.searchObj.createTime
          ? this.searchObj.createTime[0]
          : "",
        endTime: this.searchObj.createTime ? this.searchObj.createTime[1] : "",
        writeOffStartTime: this.searchObj.writeOffStartTime,
        writeOffEndTime: this.searchObj.writeOffEndTime,
      };
      if (this.memberId) data.userId = this.memberId;
      //查询订单列表
      getOrderList(data).then((res) => {
        // console.log("订单数据", res.data.body);
        this.tableData = res.data.body.list;
        this.total = res.data.body.total;
      });
    },
    // 获取门店列表
    getStores() {
      getStoreList().then((res) => {
        // // console.log("门店列表", res);
        this.store_list = res.data.body.list;
      });
    },
    //退款
    refund(row) {
      this.refundVisible = true;
      this.selectedItem = row;
    },
    //确认申请退款
    confirm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          submitOrderRefund({
            orderCode: this.selectedItem.orderCode,
            refundReason: this.form.reason,
            source: 2, //1用户自己发退款 2后台运营人员发起退款
          })
            .then((res) => {
              this.$message({
                type: "success",
                message: res.data.msg,
              });
              this.refundVisible = false;
              this.initData();
            })
            .catch((err) => {
              // console.log(err);
            });
        } else {
          return false;
        }
      });
    },
    // 重置搜索条件
    reset() {
      this.searchObj = {
        orderNumber: "",
        phone: null,
        status: null,
        storeId: null,
        createTime: "",
      };
      this.initData();
    },
    cancel() {
      try {
        this.$refs.form.resetFields();
      } catch (err) {}

      this.refundVisible = false;
    },
    // 获取订单状态与类型
    getString(type, val) {
      let temp = "";
      if (type == 1) {
        this.order_status.forEach((e) => {
          if (e.id == val) {
            temp = e.title;
          }
        });
      } else {
        this.order_type.forEach((e) => {
          if (e.id == val) {
            temp = e.title;
          }
        });
      }
      return temp;
    },
    search() {
      this.initData();
    },
    syncPageData(data) {
      this.pageNum = data.num;
      this.pageSize = data.size;
    },
    changeWriteOffTime() {
      if(this.writeOffTime) {
        this.searchObj.writeOffStartTime = this.writeOffTime[0];
        this.searchObj.writeOffEndTime = this.writeOffTime[1];
      } else {
        this.searchObj.writeOffStartTime = "";
        this.searchObj.writeOffEndTime = "";
      }
    },
    //导出功能
    exportFile() {
      let params = {
        orderCode: this.searchObj.orderNumber,
        phone: this.searchObj.phone,
        storeId: this.searchObj.storeId,
        status:
                this.searchObj.status || this.searchObj.status == 0
                        ? this.searchObj.status
                        : "",
        startTime: this.searchObj.createTime ? this.searchObj.createTime[0] : "",
        endTime: this.searchObj.createTime ? this.searchObj.createTime[1] : "",
        writeOffStartTime: this.searchObj.writeOffStartTime,
        writeOffEndTime: this.searchObj.writeOffEndTime,
      };
      downFile2(process.env.VUE_APP_BASE_API + '/customer-service/lrCateringOrder/exportCateringOrder', '餐饮订单', params, 'post')
              .then((data) => {
                console.log(data)
                if (!data) {
                  this.$message.warning("文件下载失败")
                  return
                }
                if (typeof window.navigator.msSaveBlob !== 'undefined') {
                  window.navigator.msSaveBlob(new Blob([data], {type: 'application/vnd.ms-excel'}), fileName + '.xls')
                } else {
                  let url = window.URL.createObjectURL(new Blob([data], {type: 'application/vnd.ms-excel'}))
                  let link = document.createElement('a')
                  link.style.display = 'none'
                  link.href = url
                  link.setAttribute('download', 'excel导出文件.xls')
                  document.body.appendChild(link)
                  link.click()
                  document.body.removeChild(link); //下载完成移除元素
                  window.URL.revokeObjectURL(url); //释放掉blob对象
                }
              })
    },
  },
};
</script>
<style lang="scss" scoped>
.txt-tip-info {
  // color: #409eff;
  cursor: pointer;
}
.capture {
  padding: 20px;
  color: #000;
}
.capture div {
  margin-bottom: 5px;
}
.captureTable {
  border-collapse: collapse;
  border-top: solid #ccc 1px;
  border-left: solid #ccc 1px;
  width: 100%;
  margin-bottom: 5px;
}
.captureTable td,
.captureTable th {
  border-right: solid #ccc 1px;
  border-bottom: solid #ccc 1px;
  text-align: center;
  height: 40px;
}
.hr {
  height: 1px;
  width: 100%;
  background: #999;
  margin-bottom: 5px;
  display: inline-block;
}
</style>
