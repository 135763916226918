<template>
  <div>
    <!-- 顶部会员信息 -->
    <el-row>
      <el-col :xs="24" :sm="24" :lg="24">
        <div class="pad-container">
          <!-- 顶部文字信息 -->
          <div class="flx-row ali-c top-info">
            <img class="avatar" :src="userData.headPortrait" alt="图片加载失败，请刷新" />
            <div class="flx-col ali-fs ml-25">
              <div>
                <span>会员昵称：{{userData.nickname}}</span>
                <span class="ml-40 mr-40">会员ID：{{userData.cdmcid}}</span>
                <span class="ml-40">注册时间：{{userData.cdmmindate}}</span>
              </div>
              <div class="mt-24">
                <span>手机号码：{{userData.phone}}</span>
                <span class="ml-40">会员等级：{{userData.cdmtype == 18? "金卡会员": userData.cdmtype == 20? "钻卡会员": userData.cdmtype == 21? "新会员金卡" : userData.cdmtype == 23?"铂卡会员": ""}}</span>
              </div>
            </div>
          </div>
          <!-- 操作按钮 -->
          <div class="flx-row ali-c mt-24">
            <el-button type="primary" @click="openCoupon">送优惠券</el-button>
            <el-button type="primary" class="ml-15" @click="showInt = true">修改积分</el-button>
          </div>
          <!-- 标签栏 -->
          <el-tabs v-model="currentTab" class="mt-24" @tab-click="alterTab">
            <el-tab-pane
              v-for="(item, index) in tabList"
              :key="index"
              :label="item.name"
              :name="item.id"
            ></el-tab-pane>
          </el-tabs>
          <!-- 标签栏内容 -->
          <div class="mt-10">
            <!-- <transition name="bounce"> -->
            <profile-infomation v-if="currentTab == '0'" :userData="userData" /> <!-- 概况信息 -->
            <!-- <trade-details v-if="currentTab == '1'" /> -->        
            <int-details v-if="currentTab == '2'" />    <!-- 积分明细 -->
            <department-record v-if="currentTab == '3'" :memberId="memberId" />   <!--百货购物记录-->
            <supermarket-record v-if="currentTab == '4'" :memberId="memberId" />   <!-- 商超购物记录-->
            <restaurant-record v-if="currentTab == '9'" :memberId="memberId" />   <!-- 餐饮购物记录-->
            <activity-record v-if="currentTab == '5'" :memberId="memberId"/><!--活动记录-->
            <coupon-details v-if="currentTab == '6'" :memberId="memberId" />   <!-- 优惠券明细-->
            <operation-log v-if="currentTab == '8'" />
            <Address v-if="currentTab == '7'" :tableData="addressData" />
            <!-- </transition> -->
          </div>
        </div>
      </el-col>
    </el-row>

    <!-- 批量修改积分 -->
    <el-dialog title="修改积分" :visible.sync="showInt" width="30%">
      <el-form :model="intForm" label-width="100px" label-position="right">
        <el-form-item
          label="修改积分"
          prop="points"
          :rules="[
            { required: true, message: '数字不能为空' },
            { type: 'number', message: '积分必须为数字值' }
          ]"
        >
          <el-input-number v-model.number="intForm.points" controls-position="right"></el-input-number>
          <el-popover placement="top-start" width="250" trigger="hover" content="输入正数表示增加，负数则减少">
            <i slot="reference" class="el-icon-question c-9"></i>
          </el-popover>
        </el-form-item>
        <el-form-item label="备注" prop="remark" :rules="[{ required: true, message: '备注不能为空' }]">
          <el-input
            v-model="intForm.remark"
            type="textarea"
            placeholder="请输入增减积分原因"
            maxlength="100"
            show-word-limit
            rows="5"
          ></el-input>
        </el-form-item>
        <el-form-item label="操作人员">
          <div>{{userName}}</div>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="showInt = false">取 消</el-button>
        <el-button
          type="primary"
          @click="updatePoint"
          :disabled="intForm.remark == '' || intForm.points == 0"
        >确 定</el-button>
      </span>
    </el-dialog>

    <!-- 优惠券弹窗   @getTableList='getTableList'-->
    <DepartmentGoodsDialog
      ref="GoodsDialog"
      :tableHeader="tableHeader"
      @getSelectedList="getSelectedList"
      :searchArr='searchArr'
      :keyId="'couponId'"
      :selected='true'
    ></DepartmentGoodsDialog>
  </div>
</template>
<script>
import Pagination from "@/components/Pagination/index";
import CouponDetails from "./components/CouponDetails";
import IntDetails from "./components/IntDetails";
import OperationLog from "./components/OperationLog";
import ProfileInfomation from "./components/ProfileInfomation";
import TradeDetails from "./components/TradeDetails";
import SupermarketRecord from "./components/SupermarketRecord";
import DepartmentRecord from "./components/DepartmentRecord";
import RestaurantRecord from "./components/RestaurantRecord";
import ActivityRecord from "./components/ActivityRecord";
import Address from "./components/Address";
import {getMemberDetail, updateIntegral, getAddressList,singleUserReceiveCoupon} from "@/api/members/members_manage";
import { getCouponList } from "@/api/maketing/coupons"
import DepartmentGoodsDialog from "@/components/DepartmentGoodsDialog";
export default {
  name: "members_details",
  components: {
    CouponDetails,
    IntDetails,
    OperationLog,
    ProfileInfomation,
    TradeDetails,
    SupermarketRecord,
    DepartmentRecord,
    RestaurantRecord,
    ActivityRecord,
    Pagination,
    Address,
    DepartmentGoodsDialog
  },
  data() {
    return {
      addressData: [],
      showInt: false,
      // 修改积分form
      intForm: {
        points: "",
        remark: ""
      },
      currentTab: "0",
      tabList: [
        { name: "概况信息", id: "0" },
        // { name: "交易明细", id: "1" },
        { name: "积分明细", id: "2" },
        { name: "百货购物记录", id: "3" },
        { name: "商超购物记录", id: "4" },
        { name: "餐饮购物记录", id: "9" },
        { name: "活动记录", id: "5" },
        { name: "优惠券明细", id: "6" },
        { name: "收货地址", id: "7" },
        { name: "操作日志", id: "8" },
      ],
      memberId: "",
      userData: {},
      userName: this.$store.getters.name,
      tableHeader: [  // 优惠券列表表头
         // 优惠券列表表头
        {
          value: "优惠券名称",
          key: "name",
        },
        {
          value: "优惠券类型",
          key: "coupon",
        },
        {
          value: "卡券有效期",
          key: "validTime",
        },
        {
          value: "领取条件",
          key: "drawCondition",
        },
        {
          value: "剩余张数",
          key: "stock",
        },
        {
          value: "已领取数量",
          key: "received",
        },
        {
          value: "每人限领",
          key: "limitAmount",
        },
      ],
      searchArr:[
        {
          name: "searchKey",
          value: "",
          placeholder: "请输入关键字"
        },
        {
          name: "couponClass",
          value: "",
          placeholder: "请选择类型"
        }
      ],
      // couponType: ["","通用劵", "商品劵","餐饮优惠券","折扣券", "代金券","停车券"],
    };
  },
  mounted() {
    this.handleRefresh();
  },
  beforeDestroy() {
    sessionStorage.setItem("TAB_IDX", 0);
  },
  methods: {
    getAddress() {
      getAddressList(this.memberId).then(res => {
        this.addressData = res.data.body.list;
      })
    },
    handleRefresh() {
      this.memberId = this.$route.params.id
        ? this.$route.params.id
        : sessionStorage.getItem("MEMBER_ID");
      this.$store.commit("SET_MEMBERID", this.memberId);
      if (this.$route.params.id)
        sessionStorage.setItem("MEMBER_ID", this.memberId);
      if (this.memberId) this.getMemberDetails(this.memberId);
      this.currentTab =
        sessionStorage.getItem("TAB_IDX") != undefined
          ? sessionStorage.getItem("TAB_IDX")
          : "0";
    },
    //会员详情
    getMemberDetails(id) {
      getMemberDetail(id).then(res => {
        res.data.body.cdmmindate = typeof res.data.body.cdmmindate == 'string' ? res.data.body.cdmmindate.substr(0, 10) : ""
        this.userData = res.data.body;
        // console.log(res.data.body)
      })
    },
    //   获取已勾选优惠券
    getSelectedList(coupons) {
      // console.log(coupons)
      // console.log(this.memberId)
      let couponIds = [];
      coupons.forEach(ele => {
        couponIds.push(ele.couponId)
      });
       let data = {
        userId: this.memberId,
        couponIds,
        couponSource: 2
      };
      singleUserReceiveCoupon(data).then(res => {
        this.$message({
          message: "操作成功",
          type: "success"
        });

      });
    },
    // getTableList(data){ //获取优惠券列表
    //   let params = { ...data}
    //   params.status = params.couponStatus
    //   delete params.couponStatus
    //   params.type = params.couponType
    //   getCouponList(params).then(res => {
    //      let data = this.$refs.GoodsDialog;
    //     data.total = res.data.body.total;
    //     data.tableData = res.data.body.list;
    //   });
    // },
     // 打开优惠券列表弹窗
    openCoupon() {
       let data = this.$refs.GoodsDialog;
       // console.log(data)
      //  data.act_form.list = [];
      //  data.selected_temp = JSON.parse(JSON.stringify([]));
       data.showGoods = true;
       data.getGoods(true);
    },
    updatePoint() {
      let data = {
        points: this.intForm.points,
        remark: this.intForm.remark,
        userId: this.memberId,
        operationPerson: this.operationPerson
      };
      updateIntegral(data, this.$store.getters.token).then(res => {
        this.$message({
          message: "操作成功",
          type: "success"
        });
        this.handleRefresh();
        this.showInt = false;
      })
    },
    alterTab(e) {
      sessionStorage.setItem("TAB_IDX", e.index);
      if (e.label == "收货地址") {
        this.getAddress();
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.avatar {
  border-radius: 50%;
  width: 100px;
  height: 100px;
  border: 4px solid #eaeaea;
  box-shadow: 0px 2px 12px 0px rgba(230, 230, 230, 0.6);
}
.top-info {
  span {
    width: 250px;
    display: inline-block;
  }
}
.tip-text-info {
  color: #409eff;
  cursor: pointer;
}
</style>
