<template>
  <div>
    <!-- 表格数据 -->
    <el-table class="mt-24 member-table" :data="tableData">
        <el-table-column label="活动标题" prop="activityTitle"></el-table-column>
          <el-table-column label="门店名称" prop="storeName"></el-table-column>
           <el-table-column label="活动开始时间" prop="startTime"></el-table-column>
            <el-table-column label="活动结束时间" prop="endTime"></el-table-column>
        <el-table-column label="活动状态" prop="status">
          <!-- 1待参加 2已核销 0已失效 -->
              <template slot-scope="scope">
                <span>
                  {{scope.row.status==0?'已失效':
                  scope.row.status==1?'待参加':
                  scope.row.status==2?'已核销':
                  ""}}
                </span>
              </template>
            </el-table-column>

     <el-table-column label="活动类型">
       <!-- 活动类型 1普通活动 2会员活动 -->
              <template slot-scope="scope">
                <span>{{scope.row.activityType==1?'普通活动':'会员活动'}}</span>
              </template>
            </el-table-column>

     
    </el-table>
    <div class="flx-row ali-c js-c mt-24">
      <pagination
        :total="total"
        :pageNum="pageNum"
        :pageSize="pageSize"
        :pageSizes="pageSizes"
        @changePage="getList"
        @syncPageData="syncPageData"
      />
    </div>
  </div>
</template>
<script>
import Pagination from "@/components/Pagination/index";
import { getMemberActivityById } from "@/api/members/members_manage";
export default {
  components: {
    Pagination,
  },
  props: {
    memberId: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      tableData: [],
      total: 0,
      pageNum: 1,
      pageSize: 10,
      pageSizes: [10, 20, 30, 50, 100],
    };
  },
  created() {},
  mounted() {
    // console.log(this.memberId);
    this.getList();
  },
  methods: {
    getList() {
      let params = {
        userId: this.memberId,
        pageNum: this.pageNum,
        pageSize: this.pageSize,
      };
      // console.log(params);
      getMemberActivityById(params)
        .then((res) => {
          this.total = res.data.body.total;
          // console.log(res.data.body);
        })
        .catch((err) => {
          // console.log(err);
        });
    },
    syncPageData(data) {
      this.pageNum = data.num;
      this.pageSize = data.size;
    },
  },
};
</script>
