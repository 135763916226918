<template>
  <div>
    <restaurant-list :isMembers="true" :memberId="memberId" />
  </div>
</template>
<script>
import RestaurantList from "@/views/transactions/orders/orders_repast_list";
export default {
  props: {
    memberId: {
      type: String,
      default: "",
    },
  },
  components: {
    RestaurantList,
  },
};
</script>
