<template>
  <div>
    <el-card class="box-card mb-20" v-for="(item,index) in tableData" :key="index">
      <div>
        <span>收件人姓名：</span>
        {{item.userName}}
      </div>
      <div>
        <span>手机号码：</span>
        {{item.phone}}
      </div>
      <div>
        <span>收货地址：</span>
        {{item.province+item.city+item.district+item.detailAddress+item.houseNumber}}
      </div>
    </el-card>
  </div>
</template>
<script>
export default {
  props: ["tableData"],
  mounted(){
    // console.log(this.tableData)
  }
};
</script>
<style scoped lang='scss'>
.box-card div {
  margin-bottom: 10px;
  span {
    width: 100px;
    display: inline-block;
    text-align: justify;
    text-align-last: justify;
  }
}
</style>