<template>
  <div>
    <!-- 顶部搜索栏 -->
    <el-row>
      <el-col :xs="24" :sm="24" :lg="24">
        <div class="searchItemBox" :class="{ 'pad-container': !isMembers }">
          <!-- <el-input
            placeholder="订单编号"
            class="search-input"
            clearable
            v-model="searchObj.orderNumber"
          ></el-input>
          <el-input
            placeholder="手机号"
            clearable
            v-if="!isMembers"
            class="search-input ml-15"
            v-model="searchObj.phone"
          ></el-input>

          <el-select
            clearable
            placeholder="订单状态"
            class="search-input ml-15"
            v-model="searchObj.status"
            v-if="!isMembers"
          >
            <el-option
              v-for="(item, index) in order_status"
              :key="index"
              :label="item.title"
              :value="item.id"
            ></el-option>
          </el-select>
          <el-select
            clearable
            placeholder="选择门店"
            class="search-input ml-15"
            v-model="searchObj.storeId"
            :disabled="disabled"
            v-if="!isMembers"
          >
            <el-option
              v-for="(item, index) in store_list"
              :key="index"
              :label="item.storeName"
              :value="item.id"
            ></el-option>
          </el-select>
          <label style="margin-left: 20px">下单时间：</label>
          <el-date-picker
            v-model="createTime"
            type="daterange"
            range-separator="至"
            value-format="yyyy-MM-dd HH:mm:ss"
            class="search-input ml-15"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            @change="datePickerChange"
          >
          </el-date-picker>
          <label style="margin-left: 20px">核销时间：</label>
          <el-date-picker
            v-model="writeOffTime"
            @change="datePickerChange2"
            value-format="yyyy-MM-dd HH:mm:ss"
            type="daterange"
            range-separator="至"
            class="search-input ml-15"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            placeholder="请选择核销时间"
          /> -->
          <div class="formBox">
            <label class="label">订单编号:</label>
            <el-input
            placeholder="请输入订单编号"
            class="search-input ml-15"
            clearable
            v-model="searchObj.orderNumber"
            ></el-input>
          </div>
          <div class="formBox">
            <label class="label">流水编号:</label>
            <el-input
            placeholder="请输入订单流水号"
            class="search-input ml-15"
            clearable
            v-model="searchObj.outTradeNo"
            ></el-input>
          </div>
          <div class="formBox">
            <label class="label">手机号:</label>
            <el-input
            placeholder="请输入手机号"
            clearable
            class="search-input ml-15"
            v-model="searchObj.phone"
            v-if="!isMembers"
            ></el-input>
          </div>
          <div class="formBox">
            <label class="label">订单状态:</label>
            <el-select
            clearable
            placeholder="请选择订单状态"
            class="search-input ml-15"
            v-model="searchObj.status"
            v-if="!isMembers"
            >
            <el-option
              v-for="(item, index) in order_status"
              :key="index"
              :label="item.title"
              :value="item.id"
            ></el-option>
          </el-select>
          </div>
          <div class="formBox">
            <label class="label">订单类型:</label>
            <el-select
            clearable
            placeholder="订单类型"
            class="search-input ml-15"
            v-model="searchObj.orderType"
            v-if="!isMembers || searchObj.orderType"
            >
            <el-option
              v-for="(item, index) in order_type"
              :key="index"
              :label="item.title"
              :value="item.id"
            ></el-option>
          </el-select>
          </div>
          <div class="formBox">
            <label class="label">选择门店:</label>
            <el-select
            clearable
            placeholder="选择门店"
            class="search-input ml-15"
            v-model="searchObj.storeId"
            :disabled="disabled"
            v-if="!isMembers"
            >
             <el-option
              v-for="(item, index) in store_list"
              :key="index"
              :label="item.storeName"
              :value="item.id"
             ></el-option>
            </el-select>
          </div>
          <div class="formBox1">
            <label class="label">下单时间:</label>
            <el-date-picker
            v-model="createTime"
            value-format="yyyy-MM-dd HH:mm:ss"
            type="daterange"
            range-separator="至"
            class="search-input ml-15"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            placeholder="请选择下单时间"
            @change="datePickerChange"
            :default-time="['00:00:00','23:59:59']"
            />
          </div>
          <div class="formBox1">
            <label class="label">核销时间:</label>
            <el-date-picker
            v-model="writeOffTime"
            value-format="yyyy-MM-dd HH:mm:ss"
            type="daterange"
            range-separator="至"
            class="search-input ml-15"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            placeholder="请选择核销时间"
            @change="datePickerChange2"
            :default-time="['00:00:00','23:59:59']"
            />
          </div>
          <el-button
            type="primary"
            icon="el-icon-search"
            class="ml-15"
            @click="search"
            >查询</el-button
          >
          <el-button icon="el-icon-refresh" class="ml-15" @click="reset"
            >重置</el-button
          >
        </div>
      </el-col>
    </el-row>
    <el-row>
      <el-col el-col :xs="24" :sm="24" :lg="24">
        <div :class="{ 'pad-container': !isMembers }">
          <div class="flx-row ali-c">
            <el-button type="primary" plain @click="batchPrint"
              >批量打印小票</el-button
            >
<!--            <el-button-->
<!--              icon="el-icon-upload2"-->
<!--              type="primary"-->
<!--              plain-->
<!--              @click="exportExcel"-->
<!--              >导出</el-button-->
<!--            >-->
            <el-upload
              class="upload-demo ml-10"
              accept=".xls,.xlsx"
              :action="urlUp"
              :limit="1"
              :show-file-list="false"
              :on-success="handleSuccess"
              ref="upload"
            >
              <el-button type="primary" plain>一键导入发货</el-button>
              <!-- <div slot="tip" class="el-upload__tip">只能上传jpg/png文件，且不超过500kb</div> -->
            </el-upload>
            <el-button type="primary" icon="el-icon-refresh" @click="exportFile()">导出</el-button>
          </div>
          <div class="flx-row ali-c"></div>
          <!-- 表格 -->
          <el-table
            class="mt-24 member-table"
            :data="tableData"
            @selection-change="handleSelectionChange"
          >
            <el-table-column type="expand">
              <template slot-scope="scope">
                <el-form label-position="left" inline class="demo-table-expand">
                  <template v-for="goods in scope.row.goodsList">
                    <el-form-item label="商品名称">
                      <span>{{ goods.goodsName }}</span>
                    </el-form-item>
                    <el-form-item label="价格">
                      <span>{{ goods.goodsPrice }}</span>
                    </el-form-item>
                    <el-form-item label="数量">
                      <span>{{ goods.goodsNum }}</span>
                    </el-form-item>
                  </template>
                </el-form>
              </template>
            </el-table-column>
            <el-table-column
              type="selection"
              :selectable="isCanSelect"
            ></el-table-column>
            <el-table-column
              label="订单编号"
              prop="orderNumber"
              width="200"
            ></el-table-column>
            <el-table-column
              label="支付流水号"
              prop="outTradeNo"
              width="200"
            ></el-table-column>
            <el-table-column label="客户昵称" prop="nickname"></el-table-column>
            <el-table-column label="手机号码" prop="phone"></el-table-column>
            <el-table-column
              label="所属门店"
              prop="storeName"
            ></el-table-column>
            <el-table-column label="订单类型">
              <template slot-scope="scope">
                <span>{{ getString(0, scope.row.orderType) }}</span>
              </template>
            </el-table-column>
            <el-table-column label="积分数量" v-if="searchObj.orderType == 2">
              <template slot-scope="scope">
                <span>{{scope.row.useIntegral}}</span>
              </template>
            </el-table-column>
            <el-table-column label="实付金额">
              <template slot-scope="scope">
                <span>￥{{ scope.row.actualPay || 0 }}</span>
              </template>
            </el-table-column>
            <el-table-column label="惠购券">
              <template slot-scope="scope">
                <span>￥{{scope.row.hgoCouponReduce || 0}}</span>
              </template>
            </el-table-column>
            <!-- <el-table-column label="付款方式" prop="pay_way"></el-table-column> -->
            <el-table-column label="订单状态">
              <template slot-scope="scope">
                <span>{{ getString(1, scope.row.status) }}</span>
                 <span v-if="scope.row.rfStatus">{{ scope.row.rfStatus==4?"(退款中)":"(拒绝退款)"}}</span>
              </template>
            </el-table-column>
            <el-table-column
              label="下单时间"
              prop="createTimeToStr"
              width="250"
            ></el-table-column>
            <el-table-column
              label="核销时间"
              prop="finishTime"
              width="250"
            ></el-table-column>
            <el-table-column label="操作" width="220">
              <template slot-scope="scope">
                <div class="flx-row ali-c">
                  <el-button @click="goDetail(scope.row)" type="text"
                    >详情</el-button
                  >
                  <el-button
                    type="text"
                    class="ml-10"
                    @click="getDetail(scope.row)"
                    v-if="scope.row.status == 1"
                    >打印小票</el-button
                  >
                  <el-button
                    type="text"
                    class="ml-10"
                    @click="sendOutGoods(scope.row)"
                    v-if="scope.row.status == 1 && !scope.row.rfStatus && scope.row.deliveryType==1"
                    >确定发货</el-button
                  >
                  <el-button
                    type="text"
                    class="ml-10"
                    v-if="
                      ((scope.row.status > 0 &&
                        scope.row.status < 4 &&
                        scope.row.actualPay != 0 &&
                        !scope.row.rfStatus) ||
                        scope.row.status == -1)&& roleType != 4
                    "
                    @click="refund(scope.row)"
                    >退款</el-button
                  >
                </div>
              </template>
            </el-table-column>
          </el-table>
          <div class="flx-row ali-c js-c mt-24">
            <Pagination
              :total="total"
              :pageNum="pageNum"
              :pageSize="pageSize"
              :pageSizes="pageSizes"
              @changePage="search"
              @syncPageData="syncPageData"
            />
          </div>
        </div>
      </el-col>
    </el-row>
    <!-- 确认发货 -->
    <!-- 确认发货弹窗 和 修改物流单号 弹窗 -->
    <el-dialog :visible.sync="showDialog" width="680px" title="订单发货">
      <el-form :model="role_form" label-width="80px">
        <el-form-item label="收件人">
          <div class="c-6 pb-10">
            收货人:&nbsp; &nbsp;{{ captureObj.linkman }}
          </div>
          <div class="c-6 pb-10">
            手机号:&nbsp;&nbsp;{{ captureObj.telephone }}
          </div>
          <div class="c-6 pb-10">地址:&nbsp;&nbsp;{{ captureObj.address }}</div>
        </el-form-item>
        <el-form-item label="快递公司">
          <el-select
            v-model="role_form.express"
            placeholder="请选择快递公司"
            class="form-input"
          >
            <el-option
              v-for="(item, index) in roleTypeList"
              :key="index"
              :label="item.role"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="快递单号">
          <el-input
            type="input"
            :rows="4"
            v-model="role_form.waybill"
            placeholder="请输入快递单号"
            class="form-input"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button plain @click="showDialog = false">取 消</el-button>
        <el-button type="primary" @click="confirmSubmit">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import Pagination from "@/components/Pagination";
import { getStoreList } from "@/api/online_department_store/commodity_manage";
import {downFile2} from "@/utils/index.js";
import {
  getOrderList,
  getOrderDetail,
  confirmSendOut,
  refundOrder,
  printSmallTicket
} from "@/api/transactions/order/orders_department";
import html2canvas from "html2canvas";
import vueQr from "vue-qr";
export default {
  name: "orders-department-list",
  components: {
    Pagination,
    vueQr,
  },
  data() {
    return {
      qrText: "",
      captureObj: {},
      img: "",
      dialogVisible: false,
      showDialog: false, //确认发货的弹框
      role_form: {
        express: "顺丰速运", //快递公司
        waybill: "",
      },
      roleTypeList: [
        {
          value: "顺丰速运",
          label: "顺丰速运",
        },
      ], //快递公司
      createTime: "",
      writeOffTime: "", //核销时间
      //搜索栏相关数据
      searchObj: {
        orderNumber: "",
        phone: null,
        status: null,
        storeId: null,
        orderType: null,
        photoTime1: "", //下单开始时间
        photoTime2: "", //下单结束时间
        writeOffStartTime: "",
        writeOffEndTime: "",
         outTradeNo:null,
      },
      // 订单状态与门店列表
      order_status: [
        {
          id: 0,
          title: "未支付",
        },
        {
          id: 1,
          title: "已支付",
        },
        {
          id: 2,
          title: "已发货",
        },
        {
          id: 3,
          title: "已完成",
        },
        {
          id: 4,
          title: "退款中",
        },
        {
          id: 5,
          title: "已退款",
        },
        {
          id: 6,
          title: "已失效",
        },
        {
          id: -1,
          title: "拒绝退款",
        },
         {
          id: 15,
          title: "拼团中",
        },
      ],
      order_type: [
        {
          id: 1,
          title: "正常交易",
        },
        {
          id: 2,
          title: "积分活动",
        },
        {
          id: 3,
          title: "砍价活动",
        },
        {
          id: 6,
          title: "多人拼团",
        },
        {
          id: 7,
          title: "限时抢购",
        },
         {
          id: 8,
          title: "分享集卡",
        },
      ],
      store_list: [],
      // 会员表格数据内容
      tableData: [],
      //用于分页
      total: 0,
      pageNum: 1,
      pageSize: 10,
      pageSizes: [10, 20, 30, 50, 100],
      url: "/order-service/order/exportOrder",
      disabled: false,
      multipleSelection: [],
      urlUp:"",
      roleType:'',
    };
  },
  props: {
    orderTypes: {
      type: [String, Number],
      default: "",
    },
    isMembers: {
      type: Boolean,
      default: false,
    },
    memberId: {
      type: String,
      default: "",
    },
  },
  mounted() {
    // console.log(this.$store.getters.storeId)
    // console.log(this.$store.getters.userObject.userRoleVos[0].type)
    console.log(this.$store.state.user.userObject.userRoleVos)
    this.roleType = this.$store.state.user.userObject.userRoleVos[0].type
    this.urlUp = process.env.VUE_APP_BASE_API+"/order-service/order/import";
    this.getStores();
    this.initData();
    this.$set(this.searchObj, "orderType", this.orderTypes);
  },
  methods: {
    // 表格导出
    exportExcel() {
      let data = {
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        ...this.searchObj,
      };
      data.status =
        this.searchObj.status || this.searchObj.status == 0
          ? this.searchObj.status
          : "";
      // console.log(this.searchObj);
      this.downFile(this.url, "订单", data);
    },
    //一键导入发货
    handleSuccess(response, file, fileList) {
      // console.log(file);
      this.$confirm(file.name, "确定导入发货", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "success",
      })
        .then(() => {
         this.$refs.upload.submit();
          this.$message({
            type: 'success',
            message: '导入成功!'
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消导入",
          });
        });
    },
    //表前  复选框
    isCanSelect(row) {
      return row.status == 1;
    },
    handleSelectionChange(val) {
      // console.log(val);
      this.multipleSelection = val;
    },
    batchPrint() {
      if (this.multipleSelection.length > 0) {
        let arr = [];
        this.multipleSelection.forEach((ele) => {
          arr.push(ele.id);
        });
        printSmallTicket(arr).then((res) => {
          // console.log(res.data);
          this.$message({
            message: "批量打印成功",
            type: "success",
          });
        });
      } else {
        this.$message({
          message: "请先选择要打印的订单",
          type: "warning",
        });
        return;
      }
    },
    //日期控件 下单时间
    datePickerChange() {
      // console.log(this.createTime);
      if (this.createTime) {
        this.searchObj.photoTime1 = this.createTime[0];
        this.searchObj.photoTime2 = this.createTime[1];
      } else {
        this.searchObj.photoTime1 = "";
        this.searchObj.photoTime2 = "";
      }
    },
    datePickerChange2() {
      if(this.writeOffTime) {
        this.searchObj.writeOffStartTime = this.writeOffTime[0];
        this.searchObj.writeOffEndTime = this.writeOffTime[1];
      } else {
        this.searchObj.writeOffStartTime = "";
        this.searchObj.writeOffEndTime = "";
      }
    },
    //去详情页面
    goDetail(row) {
      // console.log("row1", row);
      this.$router.push({
        name: "Orders_department_details",
        query: {
          id: row.id,
          member: row.customer.body && row.customer.body.cdmtype,
        },
      });
    },
    //确认发货
    sendOutGoods(row) {
      // console.log(row);
      getOrderDetail(row.id).then((res) => {
        this.captureObj = res.data.body;
        this.showDialog = true;
      });
    },
    //点击确认
    confirmSubmit() {
      if (!this.role_form.waybill) {
        this.$message({
          message: "请填写快递单号",
          type: "warning",
        });
        return;
      }
      let params = {
        id: this.captureObj.id,
        waybillNumber: this.role_form.waybill,
      };
      confirmSendOut(params).then((res) => {
        this.$message({
          message: "发货成功",
          type: "success",
        });
        this.initData();
        this.showDialog = false;
      });
    },
    //生成小票
    getDetail(row) {
      // console.log(row);
      printSmallTicket([row.id]).then((res) => {
        // console.log(res.data);
        this.$message({
          message: "打印成功",
          type: "success",
        });
      });
    },
    //初始化状态
    initData() {
      if (this.$store.getters.storeId) { //门店管理员  && this.$store.getters.userObject.userRoleVos[0].type==3
        this.searchObj.storeId = this.$store.getters.storeId;
        this.disabled = true;
      }
      console.log(this.searchObj)
      let data = {
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        ...this.searchObj,
      };
      data.status =
        this.searchObj.status || this.searchObj.status == 0
          ? this.searchObj.status
          : "";
      if (this.memberId) data.createCustormer = this.memberId;

      getOrderList(data).then((res) => {
        // // console.log("订单数据", res);
        this.tableData = res.data.body.list;
        this.total = res.data.body.total;
      });
    },
    // 获取门店列表
    getStores() {
      getStoreList().then((res) => {
        // // console.log("门店列表", res);
        this.store_list = res.data.body.list;
        // this.tableData = res.data.body;
      });
    },
    //退款
    refund(e) {
      // console.log(e);
      this.$confirm("确定对此订单进行退款?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        refundOrder({
          id: e.id, //订单id
          refundType: 1, //退款类型，0.部分退款 1.全部退款（注：目前只做全部退款，暂时不做部分退款
        }).then((res) => {
          this.$message({
            type: "success",
            message: res.data.msg,
          });
          this.initData();
        });
      });
    },
    // 重置搜索条件
    reset() {
      this.searchObj = {
        orderNumber: "",
        phone: null,
        status: null,
        storeId: null,
        orderType: null,
        photoTime1: "",
        photoTime2: "",
        outTradeNo:''
      };
      this.createTime = "";
      this.writeOffTime = "", //核销时间
      this.initData();
    },
    // 获取订单状态与类型
    getString(type, val) {
      let temp = "";
      if (type == 1) {  //订单状态
        this.order_status.forEach((e) => {
          if (e.id == val) {
            temp = e.title;
          }
        });
      } else { //订单类型
        this.order_type.forEach((e) => {
          if (e.id == val) {
            temp = e.title;
          }
        });
      }
      return temp;
    },
    search() {
      this.initData();
    },
    syncPageData(data) {
      this.pageNum = data.num;
      this.pageSize = data.size;
    },
    //导出功能
    exportFile() {
      let params = {
        orderNumber: this.searchObj.orderNumber,
        outTradeNo: this.searchObj.outTradeNo,
        phone: this.searchObj.phone,
        status: this.searchObj.status,
        orderType: this.searchObj.orderType,
        storeId: this.searchObj.storeId,
        photoTime1: this.searchObj.photoTime1,
        photoTime2: this.searchObj.photoTime2,
        writeOffStartTime: this.searchObj.writeOffStartTime,
        writeOffEndTime: this.searchObj.writeOffEndTime,
      }
      console.log(this.searchObj);
      // this.downFile(this.url, "订单", data);
      downFile2(process.env.VUE_APP_BASE_API + '/order-service/order/exportGoodsOrder', '百货订单列表', params, 'post')
              .then((data) => {
       console.log(data)
        if (!data) {
          this.$message.warning("文件下载失败")
          return
        }
        if (typeof window.navigator.msSaveBlob !== 'undefined') {
          window.navigator.msSaveBlob(new Blob([data], {type: 'application/vnd.ms-excel'}), fileName + '.xls')
        } else {
          let url = window.URL.createObjectURL(new Blob([data], {type: 'application/vnd.ms-excel'}))
          let link = document.createElement('a')
          link.style.display = 'none'
          link.href = url
          link.setAttribute('download', 'excel导出文件.xls')
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link); //下载完成移除元素
          window.URL.revokeObjectURL(url); //释放掉blob对象
        }
      })
    },

  },
};
</script>
<style lang="scss" scoped>
.txt-tip-info {
  // color: #409eff;
  cursor: pointer;
}
.capture {
  padding: 20px;
  color: #000;
}
.capture div {
  margin-bottom: 5px;
}
.captureTable {
  border-collapse: collapse;
  border-top: solid #ccc 1px;
  border-left: solid #ccc 1px;
  width: 100%;
  margin-bottom: 5px;
}
.captureTable td,
.captureTable th {
  border-right: solid #ccc 1px;
  border-bottom: solid #ccc 1px;
  text-align: center;
  height: 40px;
}
.hr {
  height: 1px;
  width: 100%;
  background: #999;
  margin-bottom: 5px;
  display: inline-block;
}
.demo-table-expand {
  font-size: 0;
}
.demo-table-expand label {
  width: 90px;
  color: #99a9bf;
}
.demo-table-expand .el-form-item {
  margin-right: 0;
  margin-bottom: 0;
  width: 20%;
}
.searchItemBox{
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  .label{
    width: 70px;
    font-size: 16px;
    font-weight: normal;
    display: block;
    line-height: 40px;
    margin-left: 16px;
    min-width: 70px;
  }
  .formBox{
    width: 25%;
    display: flex;
    justify-content: flex-start;
    margin-bottom: 12px;
  }
  .formBox1{
    width: 50%;
    display: flex;
    justify-content: flex-start;
    margin-bottom: 12px;
    .el-date-editor--daterange.el-input, .el-date-editor--daterange.el-input__inner, .el-date-editor--timerange.el-input, .el-date-editor--timerange.el-input__inner{
    width: auto;
  }
  }
}
</style>
