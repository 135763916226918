<template>
  <div>
    <OrdersDepartmentList :isMembers="true" :memberId="memberId" />
  </div>
</template>
<script>
import OrdersDepartmentList from "@/views/transactions/orders/orders_department_list";
export default {
  props: {
    memberId: {
      type: String,
      default: "",
    },
  },
  components: {
    OrdersDepartmentList,
  },
};
</script>
