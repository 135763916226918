<template>
  <div>
    <!-- 顶部筛选栏 -->
    <div class="flx-row ali-c">
      <el-select placeholder="选择优惠券状态" v-model="status" clearable>
        <el-option
          v-for="(item,index) in statusOption"
          :key="index"
          :label="item.title"
          :value="item.value"
        ></el-option>
      </el-select>
      <el-select placeholder="选择优惠券类型" v-model="couponType" clearable class="ml-15">
        <el-option
          v-for="(item,index) in typeOption"
          :key="index"
          :label="item.title"
          :value="item.value"
        ></el-option>
      </el-select>
      <el-button type="primary" class="ml-15" @click="getData">搜索</el-button>
    </div>
    <!-- 表格数据 -->
    <el-table class="mt-24 member-table" :data="tableData">
      <el-table-column label="优惠券名称">
        <template slot-scope="scope">
          {{scope.row.name}}
        </template>
      </el-table-column>
      <el-table-column label="优惠券状态" >
        <template slot-scope="scope">
          <span>{{scope.row.status==0?'待使用':
            scope.row.status==1?'已使用':
            scope.row.status==2?'已过期':''}}</span>
        </template>
      </el-table-column>
      <el-table-column label="优惠券类型">
        <template slot-scope="scope">
          <span>{{scope.row.coupon==1?'通用券':
            scope.row.coupon==2?'商品券':
            scope.row.coupon==3?'餐饮优惠券':
            scope.row.coupon==4?'折扣券':
            scope.row.coupon==5?'代金券':
            scope.row.coupon==6?'停车券':
            ''
            }}</span>
        </template>
      </el-table-column>
      <el-table-column label="过期时间" prop="validEndTime"></el-table-column>
      <el-table-column label="使用规则">
        <template slot-scope="scope">
          <span v-if="scope.row.useCondition==2"> 满{{scope.row.fullMoney}}减{{scope.row.reducedAmount}}</span>
          <span v-else>不限制</span>
        </template>
      </el-table-column>
    </el-table>
    <Pagination :total="total" :pageNum="pageNum" :pageSize="pageSize" :pageSizes="pageSizes" @changePage="getData" @syncPageData="syncPageData"/>
  </div>
</template>
<script>
import Pagination from "@/components/Pagination/index"
import { getCouponListOfUser } from "@/api/members/members_manage";

export default {
  components: {
    Pagination
  },
    props: {
    memberId: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      status: null,  //优惠券状态
      couponType: null, //选中的值  优惠券类型
      statusOption: [
        // 状态 0待使用 1已使用 2已过期
        {
          value: 0,
          title: "待使用"
        },
        {
          value: 1,
          title: "已使用"
        },
        {
          value: 2,
          title: "已过期"
        },
      ],
      typeOption: [
        	// 优惠券 1.通用券2.商品券3.餐饮券4.折扣券5.代金券6.停车券
        {
          value: 1,
          title: "通用券"
        },
        {
          value: 2,
          title: "商品券"
        },
        {
          value: 3,
          title: "餐饮券"
        },
         {
          value: 4,
          title: "折扣券"
        },
         {
          value: 5,
          title: "代金券"
        },
         {
          value: 6,
          title: "停车券"
        }
      ],
      tableData: [],
      total: 0,
      pageNum: 1,
      pageSize: 10,
      pageSizes: [10, 20, 30, 50, 100],
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      let params = {
        userId: this.memberId,
        status: this.status,
        coupon: this.couponType,
        pageNum: this.pageNum,
        pageSize: this.pageSize
      }
      getCouponListOfUser(params).then(res => {
        // console.log(res.data.body)
        this.total = res.data.body.total
        this.tableData = res.data.body.list
      })
    },
    syncPageData(data) {
      this.pageNum = data.num
      this.pageSize = data.size
    },
  },
};
</script>