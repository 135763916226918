<template>
  <div>
    <!-- 表格数据 -->
    <el-table class="mt-24 member-table" :data="tableData">
      <el-table-column label="积分来源" prop="type">
        <template slot-scope="scope">
          <span>
            {{scope.row.type==1?'签到增加积分':
            scope.row.type==2?'支付订单获取积分':
            scope.row.type==3?'积分商城兑换消耗积分':
            scope.row.type==4?'会员活动消耗积分':
            scope.row.type==5? "积分清零":
            scope.row.type==6? "退款回退积分":
            ''}}
          </span>
        </template>
      </el-table-column>
      <el-table-column label="积分变化">
        <template slot-scope="scope">
          <span>{{scope.row.pointsParam=='C'?'+':'-'}}{{scope.row.points}}</span>
        </template>
      </el-table-column>
      <el-table-column label="剩余积分数" prop="surplusPoints"></el-table-column>
       <el-table-column label="积分变动时间" prop="recordTime"></el-table-column>
      <el-table-column label="备注" prop="remark"></el-table-column>
    </el-table>
    <Pagination :total="total" :pageNum="pageNum" :pageSize="pageSize" :pageSizes="pageSizes" @changePage="getData" @syncPageData="syncPageData"/>
  </div>
</template>
<script>
import Pagination from "@/components/Pagination/index"
import { getPointsDetail } from "@/api/members/members_manage";

export default {
  components: {
    Pagination
  },
  data() {
    return {
      tableData: [],
      memberId: "",
      total: 0,
      pageNum: 1,
      pageSize: 10,
      pageSizes: [10, 20, 30, 50, 100],
    };
  },
  created() {
    this.memberId = sessionStorage.getItem('MEMBER_ID')
    this.getData()
  },
  methods: {
    getData(id) {
      let params = {
        userId: this.memberId,
        pageNum: this.pageNum,
        pageSize: this.pageSize
      }
      getPointsDetail(params).then(res => {
        this.total = res.data.body.total
        this.tableData = res.data.body.list
      })
    },
    syncPageData(data) {
      this.pageNum = data.num
      this.pageSize = data.size
    },
  }
};
</script>